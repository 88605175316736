import ReactGA from 'react-ga4';

import myImage from './images/IMG_1243.png';
import satyrnVPNTL from './images/SatyrnTL1.png';
import ezMovieSearch from './images/E-ZMovieSearch.png';
import SatyrnVPN from './images/SatyrnVPN.jpg';
import VPNGuides from './images/VPNGuides.png';
import SafeNotes from './images/SafeNotes.png';
import FlaskChat from './images/FlaskChat.png';
import MrFish from './images/MrFish.png';
import NotepadClone from './images/NotepadClone.png';
import SimpleMenu from './images/SimpleMenu.jpeg';
import EvoOfWebDesign from './images/EvoOfWebDesign.png';
import SysResMonitor from './images/SystemResMonitor.png';
import CurrencyCalc from './images/CurrencyCalculator.png';
import ImgRevise from './images/ImageReviser.png';
import TeamRubicon from './images/TeamRubicon.png';
import WeVPNLogo from './images/WeVPN.png';
import PokemonTechDoc from './images/PokemonDoc.png';
import PalindromePage from './images/PalindromePage.png';
import SwiftTransLogo from './images/SwiftTrans.jpg';
import CardGlassLogo from './images/CardinalGlass.jpg';
import icindproj from './images/icindustriesproj.png';
import { CiSearch } from "react-icons/ci";
import { FaGithub } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { SiFlask } from "react-icons/si";
import { FaPython } from "react-icons/fa";
import { SiCsharp } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import { SiLua } from "react-icons/si";
import { IoMdCodeWorking } from "react-icons/io";
import { MdOutlinePaid } from "react-icons/md";
import { MdOutlineVpnKey } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import { IoIosBuild } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineVolunteerActivism } from "react-icons/md";
import './App.css';

function App() {
  ReactGA.initialize('GTM-MX636X4M');
  ReactGA.send({ hitType: "pageview", page: "/", title: "My Portfolio" });
  return (
    <div className="App">
      <header className="App-header">
        <div className='portfolio-main-container'>
        <img className='my-image' src={myImage} alt="Me" />
          <h2 className='title-fonts'>
            Tyler Michl
            <p className='top-description'>
              Frontend developer and Computer Programming student
            </p>
            <p className='description-fonts'>
              I'm Tyler, a passionate programmer and developer with a love for creating innovative and practical solutions. My favorite languages are
              <a className="colored-text" href="https://www.javascript.com/" target="_blank" rel="noreferrer"> JavaScript</a> and
              <a className="colored-text" href="https://learn.microsoft.com/en-us/dotnet/csharp/" target="_blank" rel="noreferrer"> C#</a>,
              and I prefer working with <a className="colored-text" href="https://react.dev/" target="_blank" rel="noreferrer"> React </a>
              and <a className="colored-text" href="https://flask.palletsprojects.com/en/3.0.x/" target="_blank" rel="noreferrer"> Flask </a> for
              building web applications.
            </p>
            <p className='description-fonts'>
            In my free time, you can often find me experimenting with new technologies, contributing to open-source projects, and working on personal coding projects.
            </p>
            <p className='description-fonts'>
              You can check out my GitHub profile: <a className='github-link' href="https://github.com/Thymester" target='_blank' rel='noreferrer'><FaGithub /> Thymester</a>
            </p>
            <a href="https://www.linkedin.com/in/tlm99" target='_blank' rel='noreferrer'>
            <button className='avail-for-work' href="https://www.linkedin.com/in/tlm99">
              <CiSearch /> Available for Work
            </button>
          </a>
          <a href="mailto:teylver@tutanota.com" className="email-button">
            <button className='avail-for-work'><MdOutlineEmail /> Contact Me</button>
          </a>
          </h2>
          <h4 className='favproj-title-fonts'>
            My Favorite Projects
          </h4>
          <div className='grid-container'>
            <div className='grid-projimg-item'>
              <a href="https://github.com/Thymester/ImageRevise/releases" target='_blank' rel='noreferrer'>
              <img className='proj-img-top' src={ImgRevise} alt="ImageReviser App" />
              </a>
            </div>
            <div className='grid-projimg-item'>
              <a href="https://icindustriesdev.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img-top' src={icindproj} alt="IC Industries | Game Composer" />
              </a>
            </div>
            <div className='grid-projimg-item'>
              <a href="https://github.com/Thymester/PalindromeChecker" target='_blank' rel='noreferrer'>
              <img className='proj-img-top' src={PalindromePage} alt="Palindrome Checker" />
              </a>
            </div>
            <div className='grid-projimg-item'>
              <a href="https://ezmoviesearch.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img-top' src={ezMovieSearch} alt="E-Z Movie Search" />
              </a>
            </div>
            <div className='grid-projimg-item'>
              <a href="https://github.com/Thymester/PokemonTechDoc" target='_blank' rel='noreferrer'>
              <img className='proj-img-top' src={PokemonTechDoc} alt="Pokémon Technical Documentation" />
              </a>
            </div>
            <div className='grid-projimg-item'>
              <a href="https://satyrnvpn.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img-top' src={SatyrnVPN} alt="SatyrnVPN" />
              </a>
            </div>
          </div>
          <h4 className='notproj-title-fonts'>
            Notable Projects
          </h4>
          <div className='exp-container-main'>
            <a href="https://icindustriesdev.pages.dev/" target='_blank' rel='noreferrer'>
            <img className='proj-img' src={icindproj} alt="FlaskChat" />
            </a>
            <div className="framework-container">
            <FaReact /> <SiJavascript />
            </div>
            <p className='exp-type'><MdOutlinePaid /> Paid Project</p>
            <a href="https://icindustriesdev.pages.dev/" target='_blank' rel='noreferrer'>
            <h6 className='exp-title'>IC Industries</h6>
            </a>
            <p className='exp-description'>
              This is a website I created for a game composer and game developer studio called IC Industries. They specialize in creating unique 2D RPGs using RPG Maker.
            </p>
          </div>
          <div className='exp-container-secondary'>
            <a href="https://github.com/Thymester/FlaskChat" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={FlaskChat} alt="FlaskChat"/>
            </a>
            <div className="framework-container">
              <SiFlask /> <FaPython/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://github.com/Thymester/FlaskChat" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>Flask Chat</h6>
            </a>
            <p className='exp-description'>
              This simple real-time chat application is built using Flask, Socket.IO, HTML, CSS, and JavaScript. Each
              user connects to the server when the page loads and receives a
              unique ID.
            </p>
          </div>
          <div className='exp-container-main'>
            <a href="https://github.com/Thymester/SafeNotes" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={SafeNotes} alt="SafeNotes"/>
            </a>
            <div className="framework-container">
              <SiCsharp/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://github.com/Thymester/SafeNotes" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>SafeNotes</h6>
            </a>
            <p className='exp-description'>
              SafeNotes is a secure and easy-to-use notes application made in C# designed to keep your thoughts and
              information safe. With features like password verification,
              journaling, notepad functionalities, and dark mode, SafeNotes offers a comprehensive solution for
              organizing your notes securely.
            </p>
          </div>
          <div className='exp-container-secondary'>
            <a href="https://github.com/Thymester/MrFish" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={MrFish} alt="MrFish"/>
            </a>
            <div className="framework-container">
              <FaPython/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://github.com/Thymester/MrFish" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>MrFish</h6>
            </a>
            <p className='exp-description'>
              A anti-phishing Python script with headers and proxies!
            </p>
          </div>
          <div className='exp-container-main'>
            <a href="https://satyrnvpntierlist.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={satyrnVPNTL} alt="Satyrn VPN Tier List"/>
            </a>
            <div className="framework-container">
              <FaReact/> <SiJavascript/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://satyrnvpntierlist.pages.dev/" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>Satyrn VPN Tier List</h6>
            </a>
            <p className='exp-description'>
              A VPN Tier List with 5 years of research and commitment put into it!
            </p>
          </div>
          <div className='exp-container-secondary'>
            <a href="https://ezmoviesearch.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={ezMovieSearch} alt="E-Z Movie Search"/>
            </a>
            <div className="framework-container">
              <FaReact/> <SiJavascript/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://ezmoviesearch.pages.dev/" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>E-Z Movie Search</h6>
            </a>
            <p className='exp-description'>
              E-Z Movie Search: Explore movies and TV shows with ease!
            </p>
          </div>
          <div className='exp-container-main'>
            <a href="https://satyrnvpn.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={SatyrnVPN} alt="SatyrnVPN"/>
            </a>
            <div className="framework-container">
              <FaReact/> <SiJavascript/>
            </div>
            <p className='exp-type'><MdOutlineVpnKey/> VPN Service - Discontinued</p>
            <a href="https://satyrnvpn.pages.dev/" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>Satyrn VPN</h6>
            </a>
            <p className='exp-description'>
              A retired VPN project I had ran. This was a privacy focused VPN in partnership with Horizon.Pics
            </p>
          </div>
          <div className='exp-container-secondary'>
            <a href="https://vpnguides.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={VPNGuides} alt="VPNGuides"/>
            </a>
            <div className="framework-container">
              <FaReact/> <SiJavascript/>
            </div>
            <p className='exp-type'><MdOutlinePaid/> Paid Project</p>
            <a href="https://vpnguides.pages.dev/" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>VPN Guides</h6>
            </a>
            <p className='exp-description'>
              Bringing true and honest VPN reviews to you!
            </p>
          </div>
          <div className='exp-container-main'>
            <a href="https://evolution-of-web-design.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={EvoOfWebDesign} alt="Evolution of Web Design"/>
            </a>
            <div className="framework-container">
              <FaReact/> <SiJavascript/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://evolution-of-web-design.pages.dev/" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>Evolution of Web Design</h6>
            </a>
            <p className='exp-description'>
              Journey through the transformation of web pages over the years.
            </p>
          </div>
          <div className='exp-container-secondary'>
            <a href="https://github.com/Thymester/System-Resource-Monitor" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={SysResMonitor} alt="System Resource Monitor"/>
            </a>
            <div className="framework-container">
              <FaPython/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://github.com/Thymester/System-Resource-Monitor" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>System Resource Monitor</h6>
            </a>
            <p className='exp-description'>
              The System Resource Monitor is a Python application built using the Tkinter GUI toolkit. It provides
              real-time monitoring of CPU and memory usage on a computer system.
              The application allows users to set thresholds for CPU and memory usage, and it sends notifications when
              these thresholds are exceeded.
            </p>
          </div>
          <div className='exp-container-main'>
            <a href="https://currencycalc.pages.dev/" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={CurrencyCalc} alt="Currency Calculator"/>
            </a>
            <div className="framework-container">
              <FaReact/> <SiJavascript/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Work Project</p>
            <a href="https://currencycalc.pages.dev/" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>Currency Calculator</h6>
            </a>
            <p className='exp-description'>
              A currency calculator meant to calculate the total currency based on inputted bill and coin types; also
              outputs the total amount of each bill type.
            </p>
          </div>
          <div className='exp-container-secondary'>
            <a href="https://github.com/Thymester/SimpleMenu" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={SimpleMenu} alt="SimpleMenu"/>
            </a>
            <div className="framework-container">
              <SiLua />
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://github.com/Thymester/SimpleMenu" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>SimpleMenu</h6>
            </a>
            <p className='exp-description'>
              SimpleMenu is a Menu that uses NativeUI to allow for simple tasks like Weapon Spawning, Vehicle Spawning,
              Ped Changing, Weapon Clearing, and Armour and Health
              replenishing and MORE!
            </p>
          </div>
          <div className='exp-container-main'>
            <a href="https://github.com/Thymester/Notepad-Clone" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={NotepadClone} alt="Notepad Clone"/>
            </a>
            <div className="framework-container">
              <FaPython/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://github.com/Thymester/Notepad-Clone" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>Notepad Clone</h6>
            </a>
            <p className='exp-description'>
              This is a simple Notepad application developed using Python and PyQt5. It provides basic text editing
              functionalities along with additional features like syntax
              highlighting, code execution, and auto-indentation for Python code.
            </p>
          </div>
          <div className='exp-container-secondary'>
            <a href="https://github.com/Thymester/ImageRevise/releases" target='_blank' rel='noreferrer'>
              <img className='proj-img' src={ImgRevise} alt="Notepad Clone"/>
            </a>
            <div className="framework-container">
              <FaPython/>
            </div>
            <p className='exp-type'><IoMdCodeWorking/> Skill Building Project</p>
            <a href="https://github.com/Thymester/ImageRevise/releases" target='_blank' rel='noreferrer'>
              <h6 className='exp-title'>Image Revise</h6>
            </a>
            <p className='exp-description'>
              ImageRevise is a simple Tkinter-based application for resizing and optimizing images. It allows users to
              add watermarks, resize images while maintaining aspect ratio,
              and optimize them for web use. Additionally, it provides functionality to view and remove EXIF metadata
              from images.
            </p>
          </div>
          <h4 className='exp-title-fonts'>
            Skills
          </h4>
          <div className='exp-container-main'>
            <p className='exp-type'><FaNetworkWired /> Professional Skill</p>
            <a href="https://react.dev/" target='_blank' rel='noreferrer'>
            <h6 className='exp-title'>Quality Control</h6>
            </a>
            <p className='exp-description'>
              Quality control (QC) is a process through which a business seeks to ensure that product quality is maintained or improved. Quality control involves testing units 
              and determining if they are within the specifications for the final product.
            </p>
          </div>
          <div className='exp-container-secondary'>
            <p className='exp-type'><IoIosBuild /> Hobby Skill</p>
            <a href="https://react.dev/" target='_blank' rel='noreferrer'>
            <h6 className='exp-title'>ReactJS - What this site is made in.</h6>
            </a>
            <p className='exp-description'>
              React is a JavaScript-based UI development library. Although React is a library rather than a language, it is widely used in web development.
            </p>
          </div>
          <div className='exp-container-main'>
            <p className='exp-type'><IoIosBuild /> Hobby Skill</p>
            <a href="https://www.javascript.com/" target='_blank' rel='noreferrer'>
            <h6 className='exp-title'>JavaScript</h6>
            </a>
            <p className='exp-description'>
              JavaScript is a multi-paradigm, dynamic language with types and operators, inspired by Java and C syntax. It supports object-oriented programming using prototypes 
              and classes.
            </p>
          </div>
          <div className='exp-container-secondary'>
            <p className='exp-type'><IoIosBuild /> Hobby Skill</p>
            <a href="https://www.python.org/" target='_blank' rel='noreferrer'>
            <h6 className='exp-title'>Python</h6>
            </a>
            <p className='exp-description'>
            Python is an interpreted, object-oriented, high-level programming language with dynamic semantics developed by Guido van Rossum. It was originally released in 1991. 
            Designed to be easy as well as fun, the name "Python" is a nod to the British comedy group Monty Python.
            </p>
          </div>
          <div className='exp-container-main'>
            <p className='exp-type'><IoIosBuild /> Hobby Skill</p>
            <a href="https://learn.microsoft.com/en-us/dotnet/csharp/" target='_blank' rel='noreferrer'>
            <h6 className='exp-title'>C#</h6>
            </a>
            <p className='exp-description'>
            C# is pronounced "C-Sharp". It is an object-oriented programming language created by Microsoft that runs on the .NET Framework. C# has roots from the C family, and the 
            language is close to other popular languages like C++ and Java.
            </p>
          </div>
          <h4 className='volunteer-title-fonts'>
            Volunteering
          </h4>
          <div className='volunteer-container'>
          <div className='test-cont-left'>
            <p className='exp-type-2'><MdOutlineVolunteerActivism /> Volunteer</p>
            <h3 className='flex-title'><a href="https://www.facebook.com/MadisonCollegeCulturalConnect/" target='_blank' rel='noreferrer'>Cultural Connect Program</a><br /><p className='flex-description'>Aug 2022 - Dec 2022</p></h3>
            <p className='flex-description'>The Cultural Connect program is a program that groups domestic college students with first-semester international college students.</p>
          </div>
          <div className='test-cont-right'>
            <p className='exp-type-2'><MdOutlineVolunteerActivism /> Volunteer</p>
            <h3 className='flex-title'><a href="https://teamrubiconusa.org/" target='_blank' rel='noreferrer'><img className='vol-img' src={TeamRubicon} alt="Team Rubicon Logo" /></a> <a href="https://teamrubiconusa.org/" target='_blank' rel='noreferrer'>Disaster Recovery Specialist</a><br /><p className='flex-description'>Aug 2020 - Present</p></h3>
            <p className='flex-description'>
              Team Rubicon deploys on three types of operations. Response, recovery, and mitigation, but all have a similar goal: assist a community impacted by disaster. While 
              deployed on an operation, there are a number of roles you can fill, though some require additional online or in-person training.
            </p>
          </div>
          </div>
          <h4 className='exp-title-fonts'>
            Experience
            <p className='exp-title-description'>
              Some of my relevant experience.
            </p>
          </h4>
          <div className='exp-container-main'>
            <p className='exp-type'>04/2020 - 12/2022</p>
            <h6 className='exp-title'><img className='exp-img-nolink' src={WeVPNLogo} alt="WeVPN Company Logo" /> Quality Control Assistant</h6>
            <p className='exp-description'>Location: Remote</p>
            <p className='exp-description'>
              <li>
                Test and assure the development team that the bug fixes and new implementations were appropriately fixed and accounted for
              </li>
              <li>
                Write detailed write-ups of my findings technically and concisely
              </li>
              <li>
                Interact with customers and ensure changes and implementations are working accordingly
              </li>
            </p>
          </div>
          <div className='exp-container-secondary'>
            <p className='exp-type'>10/2023 - 06/2024</p>
            <h6 className='exp-title'><a href="https://www.swifttrans.com/" target='_blank' rel='noreferrer'><img className='exp-img' src={SwiftTransLogo} alt="Swift Transport Company Logo" /></a> Commercial Truck Driver</h6>
            <p className='exp-description'>Location: Appleton, WI</p>
            <p className='exp-description'>
              <li>
                Assure truck is up to DOT standards via a Pre-Trip inspection
              </li>
              <li>
                Manage my clocks and trip plan to ensure an on-time and safe delivery
              </li>
              <li>
                Maintain a professional appearance and approach at shippers and receivers
              </li>
            </p>
          </div>
          <div className='exp-container-main'>
            <p className='exp-type'>06/2021 - 02/2023</p>
            <h6 className='exp-title'><a href="https://www.cardinalcorp.com/" target='_blank' rel='noreferrer'><img className='exp-img' src={CardGlassLogo} alt="Cardinal Glass Company Logo" /></a> Tempering Lead</h6>
            <p className='exp-description'>Location: Spring Green, WI</p>
            <p className='exp-description'>
              <li>
                Lead a crew of 5-6 guys on a Furnace and help them develop the required skills for the job
              </li>
              <li>
                Manage all the scheduling and ensure everything is produced and shipped on time
              </li>
              <li>
                Assess situations with the lines and prioritize certain lines depending on external factors
              </li>
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;